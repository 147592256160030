import React from 'react';

const PageNotFound = () => {
    return (
        <div style={{textAlign: 'center', padding: 16}}>
            Nie znaleziono takiej strony
        </div>
    )
}

export default PageNotFound;
